var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('routes.roles.index')))]),_c('Datatable',{attrs:{"get_endpoint":"roles","delete_endpoint":_vm.hasLaravelRoute('roles.delete') ? 'roles' : null,"header_items":_vm.headers,"create_route":_vm.hasVueRoute('roles.add') ? 'roles.add' : null,"edit_route":_vm.hasVueRoute('roles.edit') ? 'roles.edit' : null,"user_friendly_identifier":"id","item_on_click":function (item) {
        if (_vm.hasVueRoute('roles.edit')) {
          this$1.$router.push({
            name: 'roles.edit',
            params: { id: item.id },
          })
        }
      },"show_delete":function (item) {
        return item.id !== _vm.$store.state.user.id && _vm.hasLaravelRoute('roles.delete')
      },"type":"entity_with_article.role"},scopedSlots:_vm._u([{key:"item.roles_to_assign",fn:function(ref){
      var item = ref.item;
return _vm._l((item.assignable_roles),function(role_to_assign){return _c('v-chip',{key:'role_to_assign' + role_to_assign.id,attrs:{"small":""}},[_vm._v(_vm._s(role_to_assign.translations[_vm.$i18n.locale].name)+" ")])})}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }